import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { joinNodes } from '../../actions/addRelationsAction';
import LoadingSpinner from '../loader';
import { toast } from 'react-toastify';
import ToastMessage from './toast-message';

export default function SearchResults({
  name,
  searchResult = [],
  relation,
  selectedNode = [],
  getFamilyDetails,
  selectedPath = [],
  addNode,
  toastMessage,
}) {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showLoader, setShowLoader] = useState(false);
  const [noneOfTheseButtonText, setNoneOfTheseButtonText] = useState('None of These');

  //   const [checkedState, setCheckedState] = useState(new Array(searchResult.length).fill(false));
  //   const [finalResult, setFinalResult] = useState({ uid: '', gender: '' });
  //    const [isChecked, setIsChecked] = useState(false);

  //selected_node -> node from left of tree
  //existing_node -> node from search results1

  const handleSubmit = async () => {
    setShowLoader(true);

    let finalNodeData = {
      action: 'join_existing_nodes',
      id_of_person_to_connect: searchResult[selectedIndex].uid,
      selected_node_id: selectedNode?.id,
      gender_of_person_to_connect: searchResult[selectedIndex].gender,
      relationship: relation,
    };

    const isSpouse = selectedPath.length == 0 ? true : false;
    try {
      const response = await joinNodes(finalNodeData);
      console.log('result from joining nodes ', response);
      getFamilyDetails(selectedNode.uid, selectedPath, isSpouse);
      setShowLoader(false);
      if (response.status) toast.success(<ToastMessage message={`Added ${toastMessage}`} />, { autoClose: false });
      else toast.error(`Could not add ${toastMessage}\n Error: ${response.error_message}`);
    } catch (error) {
      console.log(`Could not add ${toastMessage}`, error);
      setShowLoader(false);
      toast.error(`Could not add ${toastMessage}\n Error: ${error}`);
    }
  };

  useEffect(() => {
    if (selectedIndex != -1) handleSubmit();
  }, [selectedIndex]);

  return showLoader ? (
    <LoadingSpinner message={`Requested Search results for ${name} `} />
  ) : (
    <div>
      <h1>Results for {name} are shown here:</h1>
      <table>
        <tr>
          <th>Select</th>
          <th>Name</th>
          <th>Husband/Father Name:</th>
          <th>Gautra</th>
          <th>Village</th>
        </tr>
        {searchResult.map((result, index) => {
          //   console.log('index', index, ' - ', result);
          return (
            <tr key={index}>
              <td>
                {/* <input type="checkbox" onChange={() => handleChange(result, index)} checked={checkedState[index]} /> */}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelectedIndex(index);
                  }}
                >
                  {selectedIndex == index ? 'Loading...' : 'Select'}
                </button>
              </td>
              <td>
                <a target="_blank" href={`/family-tree?idOfUser=${result.uid}`}>
                  {result?.name}
                </a>
              </td>
              <td>
                {result.gender == 'female'
                  ? `${result.father_name ? `daughter of ${result.father_name}` : ''} ${
                      result.husband_full_name
                        ? `${result.father_name && result.husband_full_name ? ' and ' : ''}wife of ${
                            result.husband_full_name
                          }`
                        : ''
                    }`
                  : `son of ${result.father_name}`}
              </td>
              <td>{result?.gautra}</td>
              <td>{result?.village}</td>
            </tr>
          );
        })}
      </table>
      <button
        onClick={() => {
          setNoneOfTheseButtonText('Loading...');
          addNode();
        }}
      >
        {noneOfTheseButtonText}
      </button>
      {/* {finalResult.uid ? <input type="submit" onClick={handleSubmit} /> : ''} */}
    </div>
  );
}
